document.addEventListener("DOMContentLoaded", function () {
  var defaultSlickSettings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    mobileFirst: true,
    autoplay: true,
    responsive: [
      {
        breakpoint: 539,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 959,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 7,
        },
      },
    ],
  }
  var sliders = document.querySelectorAll(".papis-images-showcase .images")
  sliders.forEach(function (slider) {
    var dataOptions = slider.getAttribute("data-options")
    var enableSlider = slider.getAttribute("data-enable-slider") === "true"
    if (!enableSlider) {
      return
    }
    var options = dataOptions ? JSON.parse(dataOptions) : {}
    var slickOptions = options?.slick || {}
    var settings = Object.assign({}, defaultSlickSettings, slickOptions)
    $(slider).slick(settings)
  })
})
